import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Title, Text } from '../../components/Core'
import { useSelector } from 'react-redux'
import NewTabIcon from '../../assets/image/svg/blue_newtab.inline.svg'
import { useTranslation } from 'react-i18next'

const Content = props => {
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  const { t } = useTranslation()

  return (
    <>
      <Container
        className="mt-3 mb-5"
        style={{
          backgroundColor: userDarkMode ? '#191B20' : 'white',
          borderRadius: 10,
          padding: 30,
        }}
      >
        <Row>
          <Col lg="4" className="mb-3">
            <Title variant="SectionTitle" color={userDarkMode ? 'light' : 'dark'} className="mb-1">
              {props.title}
            </Title>
            <Text color={userDarkMode ? 'light' : 'dark'} className="mb-3">
              {props.subTitle}
            </Text>
            <a target="_blank" href={props.linkedinLink}>
              <Text color="#3B96FF">
                {props.linkedinText}
                <NewTabIcon
                  style={{
                    marginLeft: '8px',
                    marginBottom: '6px',
                    width: '16px',
                    height: '16px',
                  }}
                  aria-label={t('newTab')}
                  role="img"
                />
              </Text>
            </a>
          </Col>
          <Col lg="8" className="pl-lg-5">
            <p style={{ color: userDarkMode ? '#FAFAFA' : 'black' }}>
              {props.text1}
            </p>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Content
