import React from 'react'
import MetaData from '../../components/MetaData'
import PageWrapper from '../../components/PageWrapper'
import Hero from '../../sections/common/Hero'
import Content from '../../sections/about/TeamContent'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Section } from '../../components/Core'

const Leadership = () => {
  const { t, ready } = useTranslation(['management'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return ready && (
    <>
      <MetaData page="default" />
      <PageWrapper headerDark footerDark>
        <Hero
          className="mt-5"
          bg={userDarkMode ? 'black' : 'bg'}
          title={t('title')}
        >
        </Hero>
        <Section bg={userDarkMode ? 'black' : 'bg'} py={1}>
          <Content
            title="Farid Naib"
            subTitle="Chief Executive Officer"
            linkedinLink="https://www.linkedin.com/in/farid-naib-456b4"
            linkedinText="Farid on LinkedIn"
            text1={t('faridBio')}
          />
          <Content
            title="Thomas Gibb"
            subTitle="Chief Operations Officer"
            linkedinLink="https://www.linkedin.com/in/thomas-gibb-b8b9494"
            linkedinText="Thomas on LinkedIn"
            text1={t('thomasBio')}
          />
          <Content
            title="Kevin Ott"
            subTitle="Chief Strategy Officer"
            linkedinLink="https://www.linkedin.com/in/kevinjott"
            linkedinText="Kevin on LinkedIn"
            text1={t('kevinBio')}
          />
          <Content
            title="Robert Borden"
            subTitle="SVP"
            linkedinLink="https://www.linkedin.com/in/robbordenhh"
            linkedinText="Rob on LinkedIn"
            text1={t('robBio')}
          />
          <Content
            title="William Kehl"
            subTitle="SVP"
            linkedinLink="https://www.linkedin.com/in/williamkehl"
            linkedinText="William on LinkedIn"
            text1={t('willBio')}
          />
        </Section>
      </PageWrapper>
    </>
  )
}
export default Leadership
